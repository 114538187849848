import { useState, useEffect } from "react";
import "./App.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Incentives from "./tabs/Incentives";
import Emissions from "./tabs/Emissions";
import CostOfOwnership from "./tabs/CostOfOwnership";
import { useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import GaTracker from "./utils/GaTracker";
import Footer from "./components/Footer/Footer";
import { gql, GraphQLClient } from "graphql-request";
import { defaultTooltips } from "./data/defaultTooltips";
import { updatetooltips } from "./redux/Slices/tooltipsSlice";
import useGetData from "./utils/useGetData";
import TabPanel from "./components/TabPanel";

const queryString = require("query-string");

function App() {
  const [value, setValue] = useState(0);
  const [messages, setMessages] = useState({});
  const dispatch = useDispatch();
  const { isLoading, isError, errors, vehicleData, zipcode, activeTrim } =
    useGetData();

  useEffect(() => {
    const query = queryString.parse(window.location.search);
    let handle = "";
    if (Array.isArray(query.trims)) {
      handle = JSON.parse(query.trims[0]);
    } else {
      handle = JSON.parse(query.trims);
    }
    GaTracker.initialize();
    GaTracker.trackPage(handle.handle);
  }, []);

  const handleChange = (event, newValue) => {
    let tabName = "";

    switch (newValue) {
      case 0:
        tabName = "Incentives";
        break;

      case 1:
        tabName = "CostOfOwnership";
        break;

      case 2:
        tabName = "Emissions";
        break;

      default:
        break;
    }

    GaTracker.trackEvent({
      category: "Change Tab",
      action: "Changed the Tab",
      label: `Changed to ${tabName}`,
    });

    setValue(newValue);
  };

  //   const { data: locationData, error: locationError, isLoading: locationLoading } = useGetLocationQuery({
  //     postcode: zipcode
  //   })

  //   useEffect(() => {
  //     if (locationData) {
  //       const electricityPrice = FormatAsTwoDecimals(locationData.location.regional_fuel_cost[0].electricity / 100)
  //       const fuelPrice = FormatAsTwoDecimals(locationData.location.regional_fuel_cost[0].gasoline)
  //       dispatch(updateElectricityPrice(electricityPrice))
  //       dispatch(updateFuelPrice(fuelPrice))
  //     }
  //   }, [locationData, dispatch])

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  //amplify
  useEffect(() => {
    const fetchTranslations = async (token) => {
      let endpoint = process.env.REACT_APP_AMPLIFY_STAGING_ENDPOINT;
      let apiKey = process.env.REACT_APP_AMPLIFY_STAGING_API_KEY;

      if (process.env.REACT_APP_ENVIRONMENT === "production") {
        endpoint = process.env.REACT_APP_AMPLIFY_PRODUCTION_ENDPOINT;
        apiKey = process.env.REACT_APP_AMPLIFY_PRODUCTION_API_KEY;
      }

      const graphQLClient = new GraphQLClient(endpoint, {
        headers: {
          "X-Api-Key": apiKey,
        },
      });

      let query = gql`
        {
          listVehicleTooltips {
            items {
              handle
              incentivesTip
              zipcodeTip
              trimTip
              costOwnershipTip
              mileageTip
              mpgTip
              electricityPriceTip
              fuelPriceTip
              potentialIncentivesTip
              potentialFuelSavingsTip
              emissionsTip
              _deleted
            }
            nextToken
          }
        }
      `;

      if (token) {
        query = gql`
          {
            listVehicleTooltips(nextToken: "${token}") {
              items {
                id
                handle
                incentivesTip
                zipcodeTip
                trimTip
                costOwnershipTip
                mileageTip
                mpgTip
                electricityPriceTip
                fuelPriceTip
                potentialIncentivesTip
                potentialFuelSavingsTip
                emissionsTip
                _deleted
              }
              nextToken
            }
          }
        `;
      }

      const data = await graphQLClient.request(query);
      return data;
    };

    const setTranslations = async () => {
      const engTranslations = {};
      let token;

      while (token !== null) {
        let data = await fetchTranslations(token);
        data.listVehicleTooltips.items.forEach((translation) => {
          engTranslations[translation.handle] = translation;
        });
        token = data.listVehicleTooltips.nextToken;
      }

      for (let key in engTranslations) {
        if (engTranslations[key]._deleted) {
          delete engTranslations[key];
        }
      }

      setMessages(engTranslations);
    };

    setTranslations();
  }, []);

  useEffect(() => {
    if (vehicleData && messages) {
      const currentEv = vehicleData.vehicle.handle;
      const selectedEvTooltips = messages[currentEv];
      if (selectedEvTooltips) {
        dispatch(updatetooltips(selectedEvTooltips));
      } else {
        dispatch(updatetooltips(defaultTooltips));
      }
    } else {
      dispatch(updatetooltips(defaultTooltips));
    }
  }, [messages, vehicleData, dispatch]);

  return (
    <div className="App">
      <Box sx={{ width: "100%" }}>
        <Box>
          <Tabs
            centered
            style={{ marginBottom: "-6px" }}
            value={value}
            onChange={handleChange}
            aria-label="hyundai ev widget tabs"
            TabIndicatorProps={{
              style: { transition: "none" },
            }}
          >
            <Tab
              style={{
                marginBottom: "12px",
                fontSize: "16px",
                lineHeight: "16px",
              }}
              sx={{ fontSize: "0.875rem", fontWeight: 700, color: "#666666" }}
              label="Incentives"
              disableRipple={true}
              {...a11yProps(0)}
            />
            <Tab
              style={{
                marginBottom: "12px",
                fontSize: "16px",
                lineHeight: "16px",
              }}
              sx={{ fontSize: "0.875rem", fontWeight: 700, color: "#666666" }}
              label="Cost of Ownership"
              disableRipple={true}
              {...a11yProps(1)}
            />
            <Tab
              style={{
                marginBottom: "12px",
                fontSize: "16px",
                lineHeight: "16px",
              }}
              sx={{ fontSize: "0.875rem", fontWeight: 700, color: "#666666" }}
              label="Emissions"
              disableRipple={true}
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        {isError ? (
          <>
            {errors.length > 0
              ? errors.map((error, index) =>
                  error ? (
                    <Alert key={index} severity="error">
                      {error}
                    </Alert>
                  ) : null
                )
              : null}
          </>
        ) : isLoading ? (
          <Typography>Loading...</Typography>
        ) : vehicleData ? (
          <>
            <TabPanel value={value} index={0}>
              <Incentives
                vehicle={vehicleData.vehicle}
                vehicleIncentives={vehicleData.vehicle.incentives}
                zipcode={zipcode}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <CostOfOwnership
                vehicle={vehicleData.vehicle}
                activeTrim={activeTrim}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Emissions
                vehicle={vehicleData.vehicle}
                zipcode={zipcode}
                activeTrim={activeTrim}
              />
            </TabPanel>
          </>
        ) : null}
        <Footer />
      </Box>
    </div>
  );
}

export default App;
